<template>
  <div>
    <CyHeader
      v-if="project">
      <template #title>
        {{ project.name }}
      </template>
      <template #description>
        <div v-if="project.description">
          {{ project.description }}
        </div>
      </template>
      <template #actions>
        <div>
          <CyButton
            :icon="project.favorite ? 'star' : 'star_outline'"
            class="mr-2"
            theme="primary"
            variant="secondary"
            :loading="saving"
            @click.prevent.stop="toggleFavorite()">
            {{ $t('favorite') }}
          </CyButton>
          <CyMenu
            v-if="!_.$isEmpty($static.menuItems)"
            class="cy-project-card-header__menu"
            min-width="264px"
            offset-y
            :items="$static.menuItems">
            <template #activator="{ on, attrs }">
              <CyButton
                icon="more_horiz"
                icon-only
                theme="primary"
                variant="tertiary"
                v-bind="attrs"
                v-on="on"/>
            </template>
          </CyMenu>
        </div>
      </template>
      <template #metadata>
        <CyHeaderMetadata
          v-if="project.owner"
          :label="displayName(project.owner)">
          <CyAvatar
            xs
            :item="project.owner"/>
        </CyHeaderMetadata>
        <CyHeaderMetadata
          v-if="project.created_at"
          :label="getCreatedLabel(project)"/>
        <CyHeaderMetadata
          v-if="project.updated_at"
          :label="getUpdatedLabel(project)"/>
      </template>
    </CyHeader>

    <CyFormsProjectDelete
      v-if="modals.delete"
      :project="project"
      @cancel="closeModals"
      @success="() => $router.push({ name: 'projects' })"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CyFormsProjectDelete from '@/components/CyFormsProjectDelete.vue'
import CyHeader from '@/components/CyHeader.vue'
import CyHeaderMetadata from '@/components/CyHeaderMetadata.vue'
import { displayName, getCreatedLabel, getUpdatedLabel } from '@/utils/helpers'

export default {
  name: 'CyHeaderVariantProject',
  components: {
    CyHeader,
    CyHeaderMetadata,
    CyFormsProjectDelete,
  },
  data: () => ({
    saving: false,
    modals: {
      delete: false,
    },
  }),
  computed: {
    ...mapGetters('organization/project', [
      'project',
    ]),
    $static () {
      const { project: { canonical }, modals } = this
      const { canDisplay } = this.$cycloid.permissions
      return {
        menuItems: [
          {
            icon: 'settings',
            label: this.$t('projectSettings'),
            action: () => {
              this.$router.push({
                name: 'projectConfiguration',
                params: { backRouteTo: this.$route.name },
              })
            },
            permissionKey: 'UpdateProject',
          },
          {
            divider: true,
          },
          {
            icon: 'delete',
            label: `${this.$t('forms.btnDelete')} ${this.$t('project')}...`,
            action: () => { modals.delete = true },
            color: 'error',
            permissionKey: 'DeleteProject',
          },
        ].filter(({ permissionKey }) => !permissionKey || canDisplay(permissionKey, canonical)),
      }
    },
  },
  methods: {
    ...mapActions('organization/project', [
      'SET_FAVORITE_PROJECT',
      'UNSET_FAVORITE_PROJECT',
    ]),
    displayName,
    getCreatedLabel,
    getUpdatedLabel,
    closeModals () {
      for (const key in this.modals) this.modals[key] = false
    },
    async toggleFavorite () {
      this.$toggle.saving(true)
      const action = !this.project.favorite ? this.SET_FAVORITE_PROJECT : this.UNSET_FAVORITE_PROJECT
      await action(this.project)
      this.$toggle.saving(false)
    },
  },
  i18n: {
    messages: {
      en: {
        favorite: 'Favorite',
        projectSettings: 'Project settings',
        more: 'More',
      },
      es: {
        favorite: 'Favorito',
        projectSettings: 'Configuración del proyecto',
        more: 'Más',
      },
      fr: {
        favorite: 'Favori',
        projectSettings: 'Paramètres du projet',
        more: 'Plus',
      },
    },
  },
}
</script>
